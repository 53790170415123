import React from 'react';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './static/css/codebase.css';
import './static/css/main.css';

import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.js';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { HomeView } from './view';
import {AccountMetaData} from './lib/account';
import {fetchData} from './lib/helpers';
import { message, Spin, notification } from 'antd';
import { duration } from 'moment';

require('moment/locale/vi');

class AppComponent extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        userSystem: null,
        loading: false,
      }
  }
  notification = (options) => {
    let { type, message, description, key, duration} = options;
    notification[type]({
      message: message,
      description: description,
      key: key,
      duration: duration
    })
  }
  message = (options) => {
    let { type, key, content, duration} = options;
    message[type]({
      content: content,
      key: key,
      duration: duration
    })
  }
  render () {
    return (
      <div>
        <Router>
          <Switch>
              <Route
                path='/'
                render={props => <HomeView {...props} notification={this.notification}/>}
              />
          </Switch>
        </Router>
      </div>
    )
  }
}
export default AppComponent;
