import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination } from 'antd';
import { fetchData } from '../lib/helpers';

import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

export default class HomeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_success: [],
            data_error: [],
            list_data: [],
            run: true
        };
    }
    componentWillMount = () => {
        
    }
    sleep = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(ms);
            }, ms)
        });
    }
    onCheck = async (values) => {
        try {
            this.setState({ loading: true, data_success: [], data_error: [], run: true});
            console.log('Values form', values);
            let uids = [...this.state.list_data];
            let promise = [];
            while(uids.length > 0){
                if(!this.state.run) break;
                let uids_action = uids.splice(0, 100);
                for(let i = 0; i < uids_action.length; i++){
                    promise.push(new Promise((resolve, reject) => {
                        this.requestGraph(values['token'], uids_action[i]);
                        resolve(true)
                    }))
                }
                await this.sleep(2000);
            }
            await Promise.all(promise);
        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    message: 'Check uid',
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error'
                })
            });
        }
    }
    requestGraph = (token, id) => {
        let id_split = id.split('|');
        fetch(`https://graph.facebook.com/?access_token=${token}&ids=${id_split[0]}`)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            let data_error = this.state.data_error;
            let data_success = this.state.data_success;
            if(response.error || !response[id_split[0]]){
                data_error.push(id);
                this.setState({ data_error: data_error });
            } else{
                data_success.push(id);
                this.setState({ data_success: data_success });
            }
            if(data_error.length + data_success.length == this.state.list_data.length){
                this.setState({ loading: false });
            }
        }).catch((err) => {
            this.props.notification({ 
                message: 'Check uid',
                description: err.message || 'Đã có lỗi xảy ra',
                type: 'error',
                key: 'error'
            })
        })
    }
    render() {
        return (
            <div className="container" style={{ margin: "auto", maxWidth: "80%", marginTop: "30px"}}>
                <h2>Check uid</h2>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Form
                            onFinish={this.onCheck}
                            ref={(evt) => this.form = evt}
                            className="form-check-token"
                        >
                            <Form.Item
                                name="token"
                                rules={[{ required: true, message: 'Bạn phải nhập token!' }]}
                                label="Nhập token"
                            >
                                <Input  />
                            </Form.Item>
                            <Form.Item
                                name="uids"
                                label={`List uids (${this.state.list_data.length})`}
                                rules={[{ required: true, message: 'Bạn phải uid!' }]}
                                value={this.state.list_data.join('\n')}
                            >
                                <Input.TextArea placeholder="Nhập list uids" style={{height: "200px"}} onChange={(e) => this.setState({ list_data: e.target.value ? e.target.value.split('\n') : []})}/>
                            </Form.Item>
                            <div className="text-center">
                                <Button type="primary" className="mr-3" loading={this.state.loading} htmlType="submit" style={{width: "150px"}}>
                                    Kiểm tra
                                </Button>
                                <Button type={"ghost"} htmlType="submit" style={{width: "150px"}} disabled={!this.state.loading} onClick={() => this.setState({ loading: false, run: false})}>
                                    Dừng
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Nick sống ({this.state.data_success.length})</label>
                            <Input.TextArea style={{height: "200px"}} value={this.state.data_success.join('\n')}/>
                        </div>
                        <div className="form-group">
                            <label>Nick lỗi ({this.state.data_error.length})</label>
                            <Input.TextArea style={{height: "200px"}} value={this.state.data_error.join('\n')}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
